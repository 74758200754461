import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import HomeLayout from "../components/HomeLayout/HomeLayout"
import Card1 from "../components/Card1/Card1"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Flippy from "react-flippy/dist/Flippy"
import { FrontSide, BackSide } from "react-flippy/dist/FlippyCard"
import "../components/Card1/Card1.scss"
const Contact = () => {
  const [isFlipped1, setIsFlipped1] = useState(false)
  const [isFlipped2, setIsFlipped2] = useState(false)
  const [isFlipped3, setIsFlipped3] = useState(false)
  const [isFlipped4, setIsFlipped4] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      Copy: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Copy" } }) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Head: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "ContactFront" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Numbers: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Numbers" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }

      Symbols: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "Symbols" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  console.log(data.Copy.nodes)

  return (
    <Layout>
      <HomeLayout>
        <Flippy flipDirection="horizontal" isFlipped={isFlipped1}>
          <FrontSide
            onClick={() => {
              if (!isFlipped1) {
                setIsFlipped4(false)
                setIsFlipped2(false)
                setIsFlipped3(false)

                setIsFlipped1(true)
              } else {
                setIsFlipped1(false)
                console.log("sa")
              }
            }}
          >
            <Img
              fluid={data.Head.nodes[0].childImageSharp.fluid}
              alt="Front image"
            />
          </FrontSide>
          <BackSide>
            <div className="Card_up">
              <a
                onClick={() => {
                  navigator.clipboard.writeText(9246352385).then(
                    () => alert("Copied!"),
                    e => console.log(e)
                  )
                }}
              >
                <Img
                  fluid={data.Copy.nodes[0].childImageSharp.fluid}
                  fadeIn={false}
                />
              </a>
              <a href="tel:+91 92463 52385">
                <Img
                  fluid={data.Symbols.nodes[0].childImageSharp.fluid}
                  fadeIn={false}
                />
              </a>
            </div>
            <a
              onClick={() => {
                if (!isFlipped1) {
                  setIsFlipped4(false)
                  setIsFlipped2(false)
                  setIsFlipped3(false)

                  setIsFlipped1(true)
                } else {
                  setIsFlipped1(false)
                  console.log("sa")
                }
              }}
            >
              <Img
                fluid={data.Numbers.nodes[0].childImageSharp.fluid}
                fadeIn={false}
              />
            </a>
          </BackSide>
        </Flippy>

        <div>
          <Flippy flipDirection="horizontal" isFlipped={isFlipped2}>
            <FrontSide
              onClick={() => {
                if (!isFlipped2) {
                  setIsFlipped1(false)
                  setIsFlipped4(false)
                  setIsFlipped3(false)

                  setIsFlipped2(true)
                } else {
                  setIsFlipped2(false)
                }
              }}
            >
              <Img
                fluid={data.Head.nodes[1].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
            <BackSide>
              <div className="Card_up">
                <a
                  onClick={() => {
                    navigator.clipboard.writeText(9246352385).then(
                      () => alert("Copied!"),
                      e => console.log(e)
                    )
                  }}
                >
                  <Img
                    fluid={data.Copy.nodes[1].childImageSharp.fluid}
                    fadeIn={false}
                  />
                </a>
                <a href="sms:+91 92463 52385">
                  <Img
                    fluid={data.Symbols.nodes[1].childImageSharp.fluid}
                    fadeIn={false}
                  />
                </a>
              </div>
              <a
                onClick={() => {
                  if (!isFlipped2) {
                    setIsFlipped1(false)
                    setIsFlipped4(false)
                    setIsFlipped3(false)

                    setIsFlipped2(true)
                  } else {
                    setIsFlipped2(false)
                  }
                }}
              >
                <Img
                  fluid={data.Numbers.nodes[1].childImageSharp.fluid}
                  fadeIn={false}
                />
              </a>
            </BackSide>
          </Flippy>
        </div>

        <div>
          <Flippy flipDirection="horizontal" isFlipped={isFlipped3}>
            <FrontSide
              onClick={() => {
                if (!isFlipped3) {
                  setIsFlipped1(false)
                  setIsFlipped2(false)
                  setIsFlipped4(false)

                  setIsFlipped3(true)
                } else {
                  setIsFlipped3(false)
                }
              }}
            >
              <Img
                fluid={data.Head.nodes[2].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
            <BackSide>
              <div className="Card_up">
                <a
                  onClick={() => {
                    navigator.clipboard.writeText("9246352385").then(
                      () => alert("Copied!"),
                      e => console.log(e)
                    )
                  }}
                >
                  <Img
                    fluid={data.Copy.nodes[2].childImageSharp.fluid}
                    fadeIn={false}
                  />
                </a>
                <a href="https://api.whatsapp.com/send?phone=+9192463 52385">
                  <Img
                    fluid={data.Symbols.nodes[2].childImageSharp.fluid}
                    fadeIn={false}
                  />
                </a>
              </div>
              <a
                onClick={() => {
                  if (!isFlipped3) {
                    setIsFlipped1(false)
                    setIsFlipped2(false)
                    setIsFlipped4(false)

                    setIsFlipped3(true)
                  } else {
                    setIsFlipped3(false)
                  }
                }}
              >
                <Img
                  fluid={data.Numbers.nodes[2].childImageSharp.fluid}
                  fadeIn={false}
                />
              </a>
            </BackSide>
          </Flippy>
        </div>

        <div>
          <Flippy flipDirection="horizontal" isFlipped={isFlipped4}>
            <FrontSide
              onClick={() => {
                if (!isFlipped4) {
                  setIsFlipped1(false)
                  setIsFlipped2(false)
                  setIsFlipped3(false)

                  setIsFlipped4(true)
                } else {
                  setIsFlipped4(false)
                }
              }}
            >
              <Img
                fluid={data.Head.nodes[3].childImageSharp.fluid}
                alt="Front image"
              />
            </FrontSide>
            <BackSide>
              <div className="Card_up">
                <a
                  onClick={() => {
                    navigator.clipboard
                      .writeText("creations.u5a@gmail.com")
                      .then(
                        () => alert("Copied!"),
                        e => console.log(e)
                      )
                  }}
                >
                  <Img
                    fluid={data.Copy.nodes[3].childImageSharp.fluid}
                    fadeIn={false}
                  />
                </a>
                <a href="mailto:creations.u5a@gmail.com">
                  <Img
                    fluid={data.Symbols.nodes[3].childImageSharp.fluid}
                    fadeIn={false}
                  />
                </a>
              </div>
              <a
                onClick={() => {
                  if (!isFlipped4) {
                    setIsFlipped1(false)
                    setIsFlipped2(false)
                    setIsFlipped3(false)

                    setIsFlipped4(true)
                  } else {
                    setIsFlipped4(false)
                  }
                }}
              >
                <Img
                  fluid={data.Numbers.nodes[3].childImageSharp.fluid}
                  fadeIn={false}
                />
              </a>
            </BackSide>
          </Flippy>
        </div>

        <Link to="https://goo.gl/maps/yUhjaBYxEjuX5Z1P8">
          <Img
            fluid={data.Symbols.nodes[4].childImageSharp.fluid}
            alt="About images"
          />
        </Link>

        <Link to="https://www.facebook.com/ch.s.srinivas">
          <Img
            fluid={data.Symbols.nodes[5].childImageSharp.fluid}
            alt="About images"
          />
        </Link>
      </HomeLayout>
    </Layout>
  )
}

export default Contact
